<app-header></app-header>
<div class="app-body">
  <app-sidebar></app-sidebar>
  <!-- Main content -->
  <main class="main">

     <!--Breadcrumb-->
    <ol class="breadcrumb">
      <app-breadcrumbs></app-breadcrumbs>
      <!-- Breadcrumb Menu-->
      <li class="breadcrumb-menu d-md-down-none">
        <div class="btn-group" role="group" aria-label="Button group with nested dropdown">
          <!--<a class="btn" href="#"><i class="icon-speech"></i></a>-->
          <!--<a class="btn" [routerLink]="['/dashboard']"><i class="icon-graph"></i> &nbsp;Dashboard</a>-->
          <!--<a class="btn" href="#"><i class="icon-settings"></i> &nbsp;Settings</a>-->
        </div>
      </li>
    </ol>
    <!--<div style="margin-top: 1.5rem;"></div> &lt;!&ndash; Adjusting for removing breadcrumbs above &ndash;&gt;-->

    <div class="container-fluid">
      <router-outlet></router-outlet>
    </div><!-- /.conainer-fluid -->
  </main>
  <app-aside></app-aside>
</div>
<app-footer></app-footer>
