
import {single} from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { AuthService } from './auth.service';
import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';



const httpOptions = {
    // headers: new HttpHeaders({'Content-Type': 'text/plain'})
    headers: new HttpHeaders({'Content-Type': 'application/json'})
};

@Injectable()
export class RequestToResearcher {

    constructor(private authService: AuthService, private http: HttpClient) {
    }

    public sendNewRequest(request: any, vendorAPI: string): Promise<object> {
        if (this.authService.user.hasRole('employee')) {
            let body = {
                'request': request,
                'vendorAPI': vendorAPI
            };
            var retVal = this.http.post('/api/service/requestToResearcher/', body, httpOptions).pipe(single()).toPromise();
        }
        return retVal;
    }
}
