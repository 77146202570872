import {Injectable} from '@angular/core';
import {CanActivate, Router, ActivatedRouteSnapshot, RouterStateSnapshot} from '@angular/router';
import {AuthService} from "./auth.service";
import {Observable} from "rxjs";
import "rxjs/add/observable/of";
import "rxjs/add/operator/delay";
import "rxjs/add/operator/do";
import {globals} from "./globals";

@Injectable()
export class AuthGuard implements CanActivate {
    constructor(private authService: AuthService, private router: Router) {
    }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
        // Store the attempted URL for redirecting
        this.authService.redirectUrl = state.url;

        return this.checkLogin();
    }

    /**
     * Check if the user is currently signed in or not
     *
     * @returns {Observable<boolean> | Promise<boolean> | boolean}
     */
    checkLogin(): Observable<boolean> | Promise<boolean> | boolean {
        // If the user is loaded or loading
        if (this.authService.user.isLoaded$ && globals.accessToken) {
            return this.authService.user.isLoaded$;
        }

        // Navigate to the login page
        this.router.navigate(['/pages/login']);
        return false;
    }
}

@Injectable()
export class HomeGuard implements CanActivate {

    constructor(private authService: AuthService, private router: Router) {
    }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
        return new Promise((resolve, reject) => {
            if (this.authService.user && globals.accessToken) {
                this.authService.user.isLoaded$.then(() => {
                    if (this.authService.user.hasRole('researcher')) {
                        this.router.navigate(['/researcher/requests']);
                        resolve(false);
                    } else if (this.authService.user.hasRole('employee')) {
                        this.router.navigate(['/orders/manage']);
                        resolve(false);
                    } else {
                        this.router.navigate(['/pages/login']);
                        resolve(false);
                    }
                })
            } else {
                this.router.navigate(['/pages/login']);
                resolve(false);
            }
        });
    }
}

