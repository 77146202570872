import {Component, HostBinding} from '@angular/core';
import {PrintService} from './services/print.service';
import {AuthService} from "./services/auth.service";

@Component({
  selector: 'body',
  templateUrl: './app.component.html'
})
export class AppComponent {

    // @HostBinding('class.sidebar-hidden') sidebarHidden: boolean = true;

    public constructor(private authService: AuthService,
                       public printService: PrintService) {

    }

    ngOnInit() {
        // if (this.authService.user) {
        //     this.authService.user.isLoaded$.then(() => {
        //         this.sidebarHidden = !this.authService.user.hasRole('employee');
        //     });
        // }
    }
}
