
import {take} from 'rxjs/operators';
import * as _ from 'lodash';
import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Router} from "@angular/router";
import {Employee} from "./models/employee.model";
import {Researcher} from "./models/researcher.model";
import {globals} from './globals';


@Injectable()
export class AuthService {
    public user;
    public redirectUrl = '/';

    constructor(private http: HttpClient, private router: Router) {

        // If there is an accessToken then load the user
        if (globals.accessToken && globals.accessToken.userId && globals.accessToken.userModel) {
            if (globals.accessToken.userModel === 'researchers') {
                this.user = new Researcher();
            } else if (globals.accessToken.userModel === 'employees') {
                this.user = new Employee();
            }
            this.user.isLoaded$ = this.getUserWithPromise();
        }
    }


    /**
     * Fetches the user and returns a promise
     *
     * @returns {Promise<boolean>}
     */
    private getUserWithPromise(): Promise<boolean> {
        this.user.id = globals.accessToken.userId;
        return this.user.fetchPromise({
            error: () => this.logout()
        });
    }

    /**
     * Login & load the user & determine userType & Navigate
     * TODO: Handle error responses from server
     * TODO: WE SHOULD have functions that do ONE thing not 4 stated above.
     * the navigation part has been removed
     * putting the userType determination in its own function would enable us to change the http post in the error handler.
     * ... and thus allow the function to be scalable, testable and more simply constructed with the addition of new usertypes
     *
     * @param {string} email
     * @param {string} password
     */
    public login(email: string, password: string): Promise<boolean> {

        // If email and password are provided then login
        if (email && password) {
            return new Promise((resolve) => {
                // First attempt to login as a researcher
                this.http.post('api/researchers/login', {email: email, password: password}).subscribe(res => {
                    localStorage.setItem('accessToken', JSON.stringify(res));
                    globals.accessToken.set(_.defaultTo(JSON.parse(localStorage.getItem('accessToken')), {}));

                    this.user = new Researcher();
                    this.user.isLoaded$ = this.getUserWithPromise();
                    resolve(this.user.isLoaded$);
                }, err => {
                    // If not an researcher then attempt to login as an employee
                    this.http.post('api/employees/login', {email: email, password: password}).subscribe(res => {
                        localStorage.setItem('accessToken', JSON.stringify(res));
                        globals.accessToken.set(_.defaultTo(JSON.parse(localStorage.getItem('accessToken')), {}));

                        this.user = new Employee();
                        this.user.isLoaded$ = this.getUserWithPromise();
                        resolve(this.user.isLoaded$);
                    }, err => {
                        resolve(false);
                    });
                });
            });
        } else {
            return Promise.resolve(false);
        }
    }

    /**
     * Logout the user
     */
    public logout(): void {
        delete this.user.isLoaded$;
        localStorage.removeItem('accessToken');
        window.location.replace('/pages/login');
    }

    public async changePassword(accessToken: string,oldPwd: string, newPwd: string): Promise<boolean> {
        let retVal: boolean = false;

        try {
            let pwdChange: any;
            const headers = new HttpHeaders({Authorization: accessToken});

            if (this.user.hasRole('researcher')) {
                pwdChange = await this.http.post('api/researchers/change-password', {oldPassword: oldPwd, newPassword: newPwd},{headers: headers}).pipe(take(1)).subscribe();
            } else if (this.user.hasRole('employee')) {
                pwdChange = await this.http.post('api/employees/change-password', {oldPassword: oldPwd, newPassword: newPwd},{headers: headers}).pipe(take(1)).subscribe()
            }

            this.user.pw_change_date = new Date();
            this.user.save(null,{"patch":true});

            if(pwdChange){ retVal = true; }
        } catch(e) {
            retVal = false;
        }

        return retVal;
    }

    public checkPasswordConforms(oldPwd: string, newPwd: string, confirmPwd: string): string{
        let retVal: string = '';
        if (!oldPwd || !newPwd || !confirmPwd) {
            retVal = "All fields are required";
        }

        if (newPwd != confirmPwd && retVal == ''){
            retVal = "Your new password does not match the confirmed password"
        }

        let regex = new RegExp("^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})");
        if(!regex.test(newPwd) && retVal == ''){
            regex = new RegExp("^(?=.*[a-z])");
            if(!regex.test(newPwd)){
                retVal = "Your new password does not have at least one small letter"
            }

            regex = new RegExp("^(?=.*[A-Z])");
            if(!regex.test(newPwd)){
                retVal = "Your new password does not have at least one Capital letter"
            }

            regex = new RegExp("^(?=.*[0-9])");
            if(!regex.test(newPwd)){
                retVal = "Your new password does not have at least one number"
            }

            regex = new RegExp("^(?=.*[a-z])");
            if(!regex.test(newPwd)){
                retVal = "Your new password does not have at least one small letter"
            }

            regex = new RegExp("^(?=.*[!@#\$%\^&\*])")
            if(!regex.test(newPwd)){
                retVal = "Your new password does not have as least one of these symbols ! @ # $ % ^ & *"
            }

            if(newPwd.length < 8){
                retVal = "Your new password is not at least 8 letters long"
            }
        }
        return retVal;
    }

    public pullAccessToken(): any {
        let retVal = localStorage.getItem('accessToken');
        localStorage.removeItem('accessToken');
        return JSON.parse(retVal);
    }
}
