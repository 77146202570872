
import {single} from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { AuthService } from './auth.service';
import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';




const httpOptions = {
    // headers: new HttpHeaders({'Content-Type': 'text/plain'})
    headers: new HttpHeaders({'Content-Type': 'application/json'})
};

@Injectable()
export class NewRequestsService {

    constructor(private authService: AuthService, private http: HttpClient) {
    }

    public getNewRequests(clientCode: string): Promise<object> {
        if (this.authService.user.hasRole('employee')) {
            var body = {
                'clientCode': clientCode
            };
            // var retVal = this.http.post('/api/service/newRequests/', body, httpOptions).toPromise();
            var retVal = this.http.post('/api/service/newRequests/', body, httpOptions).pipe(single()).toPromise();
        }
        return retVal;
    }

    public loadCounties(clientCode: string): Promise<object> {
        if (this.authService.user.hasRole('employee')) {
            var body = {
                'clientCode': clientCode
            };
            var retVal2 = this.http.post('/api/service/loadCounties/', body, httpOptions).pipe(single()).toPromise();
        }
        return retVal2;
    }

}
