import {NgModule} from '@angular/core';
import {Routes, RouterModule} from '@angular/router';

import {AuthGuard, HomeGuard} from './services/auth-guard.service';

// Import Containers
import {FullLayout, SimpleLayout} from './containers';

export const routes: Routes = [
    {
        path: '',
        component: SimpleLayout,
        canActivate: [HomeGuard],
        pathMatch: 'full',
    },
    {
        path: '',
        component: FullLayout,
        canActivate: [AuthGuard],
        data: {
            title: 'Home'
        },
        children: [
            {
                path: 'researcher',
                loadChildren: () => import('./views/researcher/researcher.module').then(m => m.ResearcherModule)
            },
            {
                path: 'orders',
                loadChildren: () => import('./views/orders/orders.module').then(m => m.OrdersModule)
            },
            {
                path: 'requests',
                loadChildren: () => import('./views/requests/requests.module').then(m => m.RequestsModule)
            },
            {
                path: 'error-reports',
                loadChildren: () => import('./views/reports/error-reporting.module').then(m => m.ErrorReportingModule)
            },
            {
                path: 'days-to-complete',
                loadChildren: () => import('./views/admin/days-to-complete/days-to-complete.module').then(m => m.DaysToCompleteModule)
            },
            {
                path: 'department-maint',
                loadChildren: () => import('./views/admin/department-maint/department-maint.module').then(m => m.DepartmentMaintModule)
            },
            {
                path: 'researcher-maint',
                loadChildren: () => import('./views/admin/researcher-maint/researcher-maint.module').then(m => m.ResearcherMaintModule)
            },
            {
                path: 'client-maint',
                loadChildren: () => import('./views/admin/client-maint/client-maint.module').then(m => m.ClientMaintModule)
            },
            {
                path: 'sentencing-actions-maint',
                loadChildren: () => import('./views/admin/sentencing-actions-maint/sentencing-actions-maint.module').then(m => m.SentencingActionsMaintModule)
            },
            {
                path: 'search-process-maint',
                loadChildren: () => import('./views/admin/search-process-maint/search-process-maint.module').then(m => m.SearchProcessMaintModule)
            },
            {
                path: 'assign-researchers',
                loadChildren: () => import('./views/admin/assign-researchers/assign-researchers.module').then(m => m.AssignResearchersModule)
            },
            {
                path: 'action-maint',
                loadChildren: () => import('./views/admin/action-maint/action-maint.module').then(m => m.ActionMaintModule)
            },
            {
                path: 'dashboard',
                loadChildren: () => import('./views/admin/dashboard/dashboard.module').then(m => m.DashboardModule)
            },
            {
                path: 'researcher-dashboard',
                loadChildren: () => import('./views/admin/researcher-dashboard/researcher-dashboard.module').then(m => m.ResearcherDashboardModule)
            },
            {
                path: 'change-log',
                // canActivateChild: [AuthGuard],
                loadChildren: () => import('./views/change-log/change-log.module').then(m => m.ChangeLogModule)
            },
            {
                path: 'user-profile',
                loadChildren: () => import('./views/user-profile/user-profile.module').then(m => m.UserProfileModule)
            },
            {
                path: 'results',
                loadChildren: () => import('./views/results/results.module').then(m => m.ResultsModule)
            },
            {
                path: 'test',
                loadChildren: () => import('./views/test/test.module').then(m => m.TestModule)
            }
        ]
    },
    {
        path: 'pages',
        component: SimpleLayout,
        data: {
            title: 'Pages'
        },
        children: [
            {
                path: '',
                loadChildren: () => import('./views/pages/pages.module').then(m => m.PagesModule),
            }
        ]
    }
];

@NgModule({
    imports: [RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' })],
    exports: [RouterModule]
})
export class AppRoutingModule {
}
