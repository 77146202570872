
import {single} from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { AuthService } from './auth.service';
import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';




const httpOptions = {
    headers: new HttpHeaders({'Content-Type': 'application/json'})
};

@Injectable()
export class RegisterClearService {

    constructor(private authService: AuthService, private http: HttpClient) {
    }

    public postClearRegistration(client_order_num: string, clearmsg: string, clientCode: string, useAPI: number): Promise<object> {
        if (this.authService.user.hasRole('employee')) {
            var body = {
                'client_order_num': client_order_num,
                'clear_msg': clearmsg,
                'clientCode': clientCode,
                'apiID': useAPI
            };
            var retVal = this.http.post('/api/service/clear/', body, httpOptions).pipe(single()).toPromise()
        }
        return retVal;
    }

    public postGetToken(client_code: string): Promise<object> {
        let retVal;
        if (this.authService.user.hasRole('employee')) {
            let body = {
                'clientCode': client_code,
            };
            retVal = this.http.post('/api/service/getToken/', body, httpOptions).pipe(single()).toPromise()
        } else {
            retVal = false;
        }
        return retVal;
    }
}
