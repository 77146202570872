import {NgModule} from '@angular/core';
import {BrowserModule} from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import {LocationStrategy, PathLocationStrategy} from '@angular/common';
import {HttpClientModule} from '@angular/common/http';
import {FormsModule} from '@angular/forms';

import {AppComponent} from './app.component';

// Import containers
import {
    FullLayout,
    SimpleLayout
} from './containers';

const APP_CONTAINERS = [
    FullLayout,
    SimpleLayout
];

// Import components
import {
    AppAside,
    AppBreadcrumbs,
    AppFooter,
    AppHeader,
    AppSidebar
} from './components';

const APP_COMPONENTS = [
    AppAside,
    AppBreadcrumbs,
    AppFooter,
    AppHeader,
    AppSidebar
];

// Import directives
import {
    AsideToggleDirective,
    NAV_DROPDOWN_DIRECTIVES,
    SIDEBAR_TOGGLE_DIRECTIVES
} from './directives';

const APP_DIRECTIVES = [
    AsideToggleDirective,
    NAV_DROPDOWN_DIRECTIVES,
    SIDEBAR_TOGGLE_DIRECTIVES
];

// Import routing module
import {AppRoutingModule} from './app.routing';

// Import 3rd party components
import {BsDropdownModule} from 'ngx-bootstrap/dropdown';
import {TabsModule} from 'ngx-bootstrap/tabs';
// import {ChartsModule} from 'ng2-charts/ng2-charts';
// import {MultiselectDropdownModule} from 'angular-2-dropdown-multiselect';
import {LaddaModule} from 'angular2-ladda';

import {AuthService} from './services/auth.service';
import {ActionsService} from './services/actions.service';
import {AdditionalFeesService} from './services/additional-fees.service';
import {ClientCodesService} from './services/client-codes.service';
import {ContactsService} from "./services/contacts.service";
import {CustomAPIsService} from './services/custom-apis.service';
import {DepartmentsService} from './services/departments.service';
import {EmployeesService} from './services/employees.service';
import {StateAbbrService} from './services/state-abbr.service';
import {PrintService} from './services/print.service';
import {ResearchersService} from './services/researchers.service';
import {SentencingActionsService} from './services/sentencing-actions.service';
import {StatesService} from './services/states.service';
import {StatusesService} from './services/statuses.service';
import {StateCountiesService} from './services/state-counties.service';
import {AuthGuard, HomeGuard} from './services/auth-guard.service';
import { RegisterDelayService } from './services/register-delay.service';
import { RegisterClearService } from './services/register-clear.service';
import { RegisterRecordService} from './services/register-record.service';
import { NewRequestsService } from './services/new_requests.service';
import { NewClientOrdersService } from './services/new_client_orders.service';
import { LoadTableService} from './services/load_table.service';
import {SendRequest} from "./services/send-request.service";
import {RequestToResearcher} from './services/send_api_requests.service';
import {UpdatedEtaService} from './services/updated-eta.service';
import {GetObjectKeyService} from './services/get_object_key.service';
import {MiscFunctionsService} from './services/misc_functions.service';

@NgModule({
    imports: [
        BrowserModule,
        BrowserAnimationsModule,
        FormsModule,
        HttpClientModule,
        AppRoutingModule,
        BsDropdownModule.forRoot(),
        TabsModule.forRoot(),
        // ChartsModule,
        // MultiselectDropdownModule,
        LaddaModule.forRoot({
            style: 'contract',
            spinnerLines: 12
        }),
    ],
    declarations: [
        AppComponent,
        ...APP_CONTAINERS,
        ...APP_COMPONENTS,
        ...APP_DIRECTIVES
    ],
    providers: [
        AuthService,
        ActionsService,
        AdditionalFeesService,
        ClientCodesService,
        ContactsService,
        CustomAPIsService,
        DepartmentsService,
        EmployeesService,
        StateAbbrService,
        GetObjectKeyService,
        PrintService,
        ResearchersService,
        SentencingActionsService,
        StatesService,
        StatusesService,
        StateCountiesService,
        RegisterDelayService,
        RegisterClearService,
        RegisterRecordService,
        NewRequestsService,
        NewClientOrdersService,
        LoadTableService,
        UpdatedEtaService,
        SendRequest,
        RequestToResearcher,
        MiscFunctionsService,
        AuthGuard,
        HomeGuard, {
            provide: LocationStrategy,
            useClass: PathLocationStrategy
        }
    ],
    exports: [],
    bootstrap: [AppComponent]
})
export class AppModule {
}
