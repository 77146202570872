import {Injectable} from '@angular/core';
import {StateCounties} from "./collections/state-counties.collection";


@Injectable()
export class StateCountiesService {
    public lookup = new StateCounties();

    constructor() {
        this.lookup.fetch();
    }
}
