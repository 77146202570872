
import {single} from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { AuthService } from './auth.service';
import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';




const httpOptions = {
    headers: new HttpHeaders({'Content-Type': 'application/json'})
};

@Injectable()
export class RegisterRecordService {

    constructor(private authService: AuthService, private http: HttpClient) {
    }

    public postRecordRegistration(records: any, clientCode: string, useAPI: number): Promise<object> {
        if (this.authService.user.hasRole('employee')) {
            var body = {
                'records': records,
                'clientCode': clientCode,
                'apiID': useAPI
            };
            var retVal = this.http.post('/api/service/record/', body, httpOptions).pipe(single()).toPromise()
        }

        return retVal;
    }

}
