<header class="app-header navbar">
  <button class="navbar-toggler d-lg-none" type="button" appMobileSidebarToggler>&#9776;</button>
  <a class="navbar-brand" [routerLink]="['/']"></a>
  <button class="navbar-toggler d-md-down-none" type="button" appSidebarToggler>&#9776;</button>
  <ul class="nav navbar-nav ml-auto mr-3">
    <li class="nav-item dropdown" dropdown (onToggle)="toggled($event)">
      <a href class="nav-link dropdown-toggle" dropdownToggle (click)="false">
        <!--<img src="assets/img/avatars/6.jpg" class="img-avatar" alt="admin@bootstrapmaster.com">-->
        Welcome <span class="d-md-down-none">{{user.name}}</span>
      </a>
      <div class="dropdown-menu dropdown-menu-right" *dropdownMenu aria-labelledby="simple-dropdown">

        <div class="dropdown-header text-center"><strong>Account</strong></div>
        <a class="dropdown-item" href="/user-profile"><i class="fa fa-user"></i> Profile</a>
        <a *ngIf="user.hasRole('employee')" class="dropdown-item" href="/change-log"><i class="fa fa-file-text-o"></i> Change Log</a>
        <a class="dropdown-item" (click)="logout()"><i class="fa fa-sign-out"></i> Logout</a>

        <!--<a class="dropdown-item" href="#"><i class="fa fa-bell-o"></i> Updates<span class="badge badge-info">42</span></a>-->
        <!--<a class="dropdown-item" href="#"><i class="fa fa-envelope-o"></i> Messages<span class="badge badge-success">42</span></a>-->
        <!--<a class="dropdown-item" href="#"><i class="fa fa-tasks"></i> Tasks<span class="badge badge-danger">42</span></a>-->
        <!--<a class="dropdown-item" href="#"><i class="fa fa-comments"></i> Comment<span class="badge badge-warning">42</span></a>-->
        <!--<div class="dropdown-header text-center"><strong>Settings</strong></div>-->
        <!--<a class="dropdown-item" href="#"><i class="fa fa-wrench"></i> Setting</a>-->
        <!--<a class="dropdown-item" href="#"><i class="fa fa-usd"></i> Payments<span class="badge badge-default">42</span></a>-->
        <!--<a class="dropdown-item" href="#"><i class="fa fa-file"></i> Projects<span class="badge badge-primary">42</span></a>-->
        <!--<div class="divider"></div>-->
        <!--<a class="dropdown-item" href="#"><i class="fa fa-shield"></i> Lock account</a>-->

      </div>
    </li>
    <button class="navbar-toggler d-md-down-none d-none" type="button" appAsideMenuToggler>&#9776;</button>
  </ul>
</header>
