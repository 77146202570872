<div class="sidebar">
  <nav class="sidebar-nav">
    <ul class="nav">
      <li class="nav-item nav-dropdown" routerLinkActive="open" appNavDropdown *ngIf="user.hasRole('researcher')">
        <a class="nav-link nav-dropdown-toggle" href="#" appNavDropdownToggle>
          <i class="fa fa-user"></i>
          <!--<i class="fa fa-circle-o"></i>-->
          <strong>Researcher</strong>
        </a>
        <ul class="nav-dropdown-items">
          <li class="nav-item" *ngIf="user.hasRole('researcher')">
            <a class="nav-link" routerLinkActive="active" [routerLink]="['/researcher/requests']"><i
              class="fa">R</i> Requests</a>
          </li>
          <li class="nav-item" *ngIf="user.hasRole('researcher')">
            <a class="nav-link" routerLinkActive="active" [routerLink]="['/researcher/resultimport']"><i
              class="fa fa-arrow-circle-up"></i> Digital Delve Import</a>
          </li>
        </ul>
      </li>

      <li class="nav-item nav-dropdown" *ngIf="user.hasRole('researcher')" routerLinkActive="open" [routerLinkActiveOptions]="{ exact: true }">
        <a class="nav-link" [routerLink]="['/researcher/researcher-lookup']"><i class="fa fa-search ml-3"></i>Request Lookup</a>
      </li>

      <li class="nav-item nav-dropdown" routerLinkActive="open" appNavDropdown *ngIf="user.hasRole('employee')">
        <a class="nav-link nav-dropdown-toggle" href="#" appNavDropdownToggle>
          <i class="fa">O</i>
          <!--<i class="fa fa-circle-o"></i>-->
          <strong>Orders</strong>
        </a>
        <ul class="nav-dropdown-items">
          <li class="nav-item" *ngIf="user.hasRole('employee')">
            <a class="nav-link" routerLinkActive="active" [routerLink]="['/orders/manage']">
              <i class="fa fa-search ml-3"></i>
              Manage
            </a>
          </li>
          <li class="nav-item" *ngIf="user.hasRole('employee')">
            <a class="nav-link" routerLinkActive="active" [routerLink]="['/orders/new']">
              <i class="fa fa-plus ml-3"></i>
              New
            </a>
          </li>
          <li class="nav-item" *ngIf="user.hasRole('employee')">
            <a class="nav-link" routerLinkActive="active" [routerLink]="['/orders/import']">
              <i class="icon-action-undo ml-3"></i>Import
            </a>
          </li>
          <li class="nav-item" *ngIf="user.hasRole('employee')">
            <a class="nav-link" routerLinkActive="active" [routerLink]="['/orders/delayRegister']">
              <i class="icon-clock ml-3"></i>Delay
            </a>
          </li>
        </ul>
      </li>

      <li class="nav-item nav-dropdown" *ngIf="user.hasRole('employee')" routerLinkActive="open" [routerLinkActiveOptions]="{ exact: true }">
        <a class="nav-link" [routerLink]="['/requests']"><i class="fa">R</i>Requests</a>
      </li>

      <li class="nav-item nav-dropdown" *ngIf="user.hasRole('employee')" routerLinkActive="open" [routerLinkActiveOptions]="{ exact: true }">
        <a class="nav-link" [routerLink]="['/requests/lookup']"><i class="fa fa-search ml-3"></i>Lookup</a>
      </li>

      <li class="nav-item nav-dropdown" routerLinkActive="open" appNavDropdown *ngIf="user.hasRole('supervisor') || user.hasRole('admin')">
        <a class="nav-link nav-dropdown-toggle" href="#" appNavDropdownToggle><i class="fa">DB</i>
          <strong>Dashboards</strong>
        </a>
        <ul class="nav-dropdown-items">
          <li class="nav-item nav-dropdown" *ngIf="user.hasRole('supervisor') || user.hasRole('admin')" routerLinkActive="open" [routerLinkActiveOptions]="{ exact: true }">
            <a class="nav-link" [routerLink]="['/dashboard']"><i class="fa">CD</i>Client Dashboard</a>
          </li>
          <li class="nav-item nav-dropdown" *ngIf="user.hasRole('supervisor') || user.hasRole('admin')" routerLinkActive="open" [routerLinkActiveOptions]="{ exact: true }">
            <a class="nav-link" [routerLink]="['/researcher-dashboard']"><i class="fa">RD</i>Researcher Dashboard</a>
          </li>
        </ul>
      </li>

      <li class="nav-item nav-dropdown" routerLinkActive="open" appNavDropdown *ngIf="user.hasRole('admin')">
        <a class="nav-link nav-dropdown-toggle" href="#" appNavDropdownToggle><i class="fa">A</i>
          <strong>Administrator</strong>
        </a>
        <ul class="nav-dropdown-items">
          <li class="nav-item" *ngIf="user.hasRole('admin')">
            <a class="nav-link" routerLinkActive="active" [routerLink]="['/assign-researchers']"><i
              class="fa">AR</i>Assign Researchers</a>
          </li>
        </ul>
      </li>

      <li class="nav-item nav-dropdown" routerLinkActive="open" appNavDropdown *ngIf="user.hasRole('admin')">
        <a class="nav-link nav-dropdown-toggle" href="#" appNavDropdownToggle><i class="fa">M</i>
          <strong>Table Maintenance</strong>
        </a>
        <ul class="nav-dropdown-items">
          <li class="nav-item" *ngIf="user.hasRole('admin')">
            <a class="nav-link" routerLinkActive="active" [routerLink]="['/action-maint']"><i
              class="fa">A</i>Actions</a>
          </li>
          <li class="nav-item" *ngIf="user.hasRole('admin')">
            <a class="nav-link" routerLinkActive="active" [routerLink]="['/department-maint']"><i
              class="fa">D</i>Departments</a>
          </li>
          <li class="nav-item" *ngIf="user.hasRole('admin')">
            <a class="nav-link" routerLinkActive="active" [routerLink]="['/client-maint']"><i
              class="fa">C</i>Clients</a>
          </li>
          <li class="nav-item" *ngIf="user.hasRole('admin')">
            <a class="nav-link" routerLinkActive="active" [routerLink]="['/sentencing-actions-maint']"><i
              class="fa">SA</i>Sentencing Actions</a>
          </li>
          <li class="nav-item" *ngIf="user.hasRole('admin')">
            <a class="nav-link" routerLinkActive="active" [routerLink]="['/search-process-maint']"><i
              class="fa">SP</i>Search Processes</a>
          </li>
<!--          <li class="nav-item" *ngIf="user.hasRole('admin')">-->
<!--            <a class="nav-link" routerLinkActive="active" [routerLink]="['/researcher-maint']"><i-->
<!--              class="fa">R</i>Researcher Maint</a>-->
<!--          </li>-->
        </ul>
      </li>

      <li class="nav-item nav-dropdown" routerLinkActive="open" appNavDropdown>
        <a class="nav-link nav-dropdown-toggle" href="#" appNavDropdownToggle><i class="fa">RPT</i>
          <strong>Reporting</strong>
        </a>
        <ul class="nav-dropdown-items">
          <li class="nav-item">
            <a class="nav-link" routerLinkActive="active" [routerLink]="['/error-reports']"><i
              class="fa">UE</i>User Errors</a>
          </li>
        </ul>
      </li>

    </ul>
  </nav>
</div>
